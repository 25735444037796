import React from 'react';

export default function (props) {
    return (
        <svg
            {...props}
            viewBox='0 0 299.87 299.67'
        >
            <path
                d='M190.69 47.48v38.35h1.3V94h6.7V47.48a12.34 12.34 0 0 0-12.32-12.32H185a12.09 12.09 0 0 0-4.31.8V24.31A12.33 12.33 0 0 0 168.37 12H167a12.3 12.3 0 0 0-4.31.79v-.48A12.33 12.33 0 0 0 150.37 0H149a12.32 12.32 0 0 0-12.31 12.31v9.22a12.33 12.33 0 0 0-4.52-.87h-1.37a12.33 12.33 0 0 0-12.31 12.32v52.85h8V32.98a4.32 4.32 0 0 1 4.31-4.32h1.37a4.33 4.33 0 0 1 4.32 4.32v52.85h8.2V12.31A4.31 4.31 0 0 1 149 8h1.37a4.32 4.32 0 0 1 4.32 4.31v73.52h8V24.31A4.31 4.31 0 0 1 167 20h1.37a4.32 4.32 0 0 1 4.32 4.31v61.52h8V47.48a4.32 4.32 0 0 1 4.31-4.32h1.37a4.33 4.33 0 0 1 4.32 4.32Z'
                fill='teal'
            />
            <path
                d='m190.68 94 .01-8.17h1.3V94M188.79 116.83a41.16 41.16 0 0 1-80.49-12.16h-7.81v4.09a49.15 49.15 0 0 0 96.61 8.07Z'
                fill='teal'
            />
            <path
                d='M108.49 115.58a23.45 23.45 0 0 1 .34-4 5 5 0 0 1-.34-1.78V67.51a13 13 0 0 1 10 12.65v17.78a11.81 11.81 0 0 1 4.56-.94 11.67 11.67 0 0 1 3.44.54V80.16a21 21 0 0 0-21-21h-5v46.34h3.41v11h-1.59a13.06 13.06 0 0 0 7.1 5.68 23.14 23.14 0 0 1-.92-6.6Z'
                fill='teal'
            />
            <path
                d='M252.39 191.07h-38.35v1.3h-8.14v6.7h46.52a12.33 12.33 0 0 0 12.31-12.32v-1.36a12.36 12.36 0 0 0-.79-4.32h11.64a12.33 12.33 0 0 0 12.32-12.32v-1.36a12.36 12.36 0 0 0-.79-4.32h.47a12.33 12.33 0 0 0 12.32-12.32v-1.36a12.33 12.33 0 0 0-12.32-12.32h-9.22a12.12 12.12 0 0 0 .87-4.52v-1.36a12.32 12.32 0 0 0-12.33-12.36h-52.86v8h52.86a4.32 4.32 0 0 1 4.31 4.32v1.36a4.32 4.32 0 0 1-4.31 4.32h-52.86v8.2h73.51a4.33 4.33 0 0 1 4.32 4.32v1.36a4.33 4.33 0 0 1-4.32 4.32h-73.51v8h61.51a4.33 4.33 0 0 1 4.32 4.32v1.36a4.33 4.33 0 0 1-4.32 4.32h-61.51v8h38.35a4.32 4.32 0 0 1 4.31 4.32v1.36a4.32 4.32 0 0 1-4.31 4.36Z'
                fill='#0d98ba'
            />
            <path
                d='m205.87 191.06 8.17.01v1.3h-8.17M183.04 189.17a41.16 41.16 0 0 1 12.16-80.48v-7.86h-4.1a49.15 49.15 0 0 0-8.06 96.61Z'
                fill='#0d98ba'
            />
            <path
                d='M184.29 108.83a23.45 23.45 0 0 1 4 .34 5 5 0 0 1 1.78-.34h42.31a13 13 0 0 1-12.65 10H201.9a11.82 11.82 0 0 1 .94 4.57 11.6 11.6 0 0 1-.54 3.43h17.4a21 21 0 0 0 21-21v-5h-46.33v3.45h-11v-1.55a13 13 0 0 0-5.68 7.1 23.14 23.14 0 0 1 6.6-1Z'
                fill='#0d98ba'
            />
            <path
                d='M108.49 252.18v-38.35h-1.3v-8.17h-6.7v46.52a12.33 12.33 0 0 0 12.31 12.32h1.37a12.18 12.18 0 0 0 4.32-.8v11.65a12.32 12.32 0 0 0 12.31 12.31h1.37a12.4 12.4 0 0 0 4.32-.79v.48a12.32 12.32 0 0 0 12.31 12.31h1.37a12.33 12.33 0 0 0 12.32-12.31v-9.22a12.31 12.31 0 0 0 4.51.87h1.37a12.34 12.34 0 0 0 12.32-12.32v-52.85h-8v52.85a4.33 4.33 0 0 1-4.32 4.32H167a4.32 4.32 0 0 1-4.31-4.32v-52.85h-8.2v73.52a4.32 4.32 0 0 1-4.32 4.31h-1.37a4.31 4.31 0 0 1-4.31-4.31v-73.52h-8v61.52a4.32 4.32 0 0 1-4.32 4.31h-1.37a4.31 4.31 0 0 1-4.31-4.31v-61.52h-8v38.35a4.33 4.33 0 0 1-4.32 4.32h-1.37a4.32 4.32 0 0 1-4.31-4.32Z'
                fill='teal'
            />
            <path
                d='m108.5 205.67-.01 8.16h-1.3v-8.16M110.39 182.83a41.16 41.16 0 0 1 80.51 12.16h7.82v-4.09a49.15 49.15 0 0 0-96.64-8.07Z'
                fill='teal'
            />
            <path
                d='M190.69 184.08a24.53 24.53 0 0 1-.34 4 5 5 0 0 1 .34 1.78v42.32a13 13 0 0 1-10-12.65v-17.81a11.82 11.82 0 0 1-4.57.94 11.6 11.6 0 0 1-3.43-.54v17.38a21 21 0 0 0 21 21h5v-46.34h-3.45v-11h1.59a13.08 13.08 0 0 0-7.11-5.68 22.79 22.79 0 0 1 .97 6.6ZM47.48 108.83h38.35v-1.3H94v-6.7H47.48a12.32 12.32 0 0 0-12.31 12.32v1.36a12.4 12.4 0 0 0 .79 4.32H24.32A12.33 12.33 0 0 0 12 131.15v1.36a12.18 12.18 0 0 0 .8 4.32h-.48A12.33 12.33 0 0 0 0 149.15v1.36a12.33 12.33 0 0 0 12.32 12.32h9.22a12.12 12.12 0 0 0-.87 4.52v1.36a12.33 12.33 0 0 0 12.31 12.32h52.85v-8H32.98a4.32 4.32 0 0 1-4.31-4.32v-1.36a4.32 4.32 0 0 1 4.31-4.32h52.85v-8.2H12.32A4.33 4.33 0 0 1 8 150.51v-1.36a4.33 4.33 0 0 1 4.32-4.32h73.51v-8H24.32a4.33 4.33 0 0 1-4.32-4.32v-1.36a4.33 4.33 0 0 1 4.32-4.32h61.51v-8H47.48a4.32 4.32 0 0 1-4.31-4.32v-1.36a4.32 4.32 0 0 1 4.31-4.32Z'
                fill='teal'
            />
            <path
                d='m94 108.89-8.17-.02v-1.3H94M116.83 110.77a41.16 41.16 0 0 1-12.15 80.48v7.82h4.09a49.15 49.15 0 0 0 8.06-96.61Z'
                fill='teal'
            />
            <path
                d='M115.58 191.07a23.45 23.45 0 0 1-4-.34 5 5 0 0 1-1.78.34H67.52a13 13 0 0 1 12.65-10h17.78a11.34 11.34 0 0 1-.41-8H80.17a21 21 0 0 0-21 21v5h46.33v-3.45h11v1.59a13 13 0 0 0 5.68-7.1 23.14 23.14 0 0 1-6.6.96Z'
                fill='teal'
            />
        </svg>
    );
}